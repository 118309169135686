export type Language = string;

export const clientLanguages = [
  { value: 'de', flag: 'de', label: 'Deutsch' },
  { value: 'en', flag: 'gb', label: 'English' },
  { value: 'fr-CH', flag: 'fr', label: 'Français' },
  { value: 'it-CH', flag: 'it', label: 'Italiano' },
  { value: 'pl', flag: 'pl', label: 'Polski' }, // polish
  { value: 'cz', flag: 'cz', label: 'Česky' }, // czech
  { value: 'sk', flag: 'sk', label: 'Slovenský' }, // slovakian
  { value: 'ru', flag: 'ru', label: 'Русский' }, // russian
  { value: 'hu', flag: 'hu', label: 'Magyar' }, // hungarian
  { value: 'ro', flag: 'ro', label: 'Română' }, // romanian
  { value: 'nl', flag: 'nl', label: 'Nederlands' }, // dutch
  { value: 'si', flag: 'si', label: 'Slovenščina' }, // slovenian
  { value: 'bg', flag: 'bg', label: 'български' }, // Bulgarian
  { value: 'ar', flag: 'sa', label: 'العربية' }, // Arabic
] as const;

export const supportedLanguages = clientLanguages.map((language) => language.value);

export type LanguageCode = typeof clientLanguages[number]['value'];

// used to set the language for AI requests
export const clientLanguagesEnglish = [
  { value: 'de', label: 'German' },
  { value: 'en', label: 'English' },
  { value: 'fr-CH', label: 'French' },
  { value: 'it-CH', label: 'Italian' },
  { value: 'pl', label: 'Polish' },
  { value: 'cz', label: 'Czech' },
  { value: 'sk', label: 'Slovak' },
  { value: 'ru', label: 'Russian' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'ro', label: 'Romanian' },
  { value: 'nl', label: 'Dutch' },
  { value: 'si', label: 'Slovenian' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'ar', label: 'Arabic' },
] as const;

export const getLanguageLabel = (value: string): string => {
  const language = clientLanguages.find((language) => language.value === value);
  return language?.label || '';
};

export const getEnglishLanguageLabel = (value?: string): string => {
  const language = clientLanguagesEnglish.find((language) => language.value === value);
  return language?.label || 'source language';
};
